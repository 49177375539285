
























import { Vue, Component } from "vue-property-decorator";
import { reqArticleList } from "@/api";
import { NewsColumns } from "@/types/enum";
import { transformNewsString } from "@/utils";
@Component({
  name: "channels"
})
export default class extends Vue {
  news: any[] = [];

  async initList() {
    const { data: list } = await reqArticleList(NewsColumns.REPORTINGCHANNELS);
    if (list && list.length > 0) {
      this.news = transformNewsString(list);
    }
  }

  created() {
    this.initList();
  }
}
